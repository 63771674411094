import wurd from 'wurd-react';

import Split from 'components/split-page';
import Progress from 'components/unit-progress';
import UnitTypeCard from './unit-type-card';
import DatePicker from './date-picker';


const cms = wurd.block('unitDetail');


export default function UnitTypeDetail({ site, unitType, unit }) {
  return (
    <>
      <Progress site={site} unitType={unitType} unit={unit} />

      <Split className="mb-2">
        <div className="flex-1"><UnitTypeCard site={site} unitType={unitType} unit={unit} /></div>
        <div className="flex-1 mt-lg-2"><DatePicker site={site} unitType={unitType} unit={unit} /></div>
      </Split>
    </>
  );
}
