import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import * as actions from 'actions';
import config from 'config';
import Loader from 'components/loader';
import UnitTypeCard from './unit-type-card';


export default function ({ unit, site, unitType: _unitType }) {
  const queryClient = useQueryClient();
  const { data: unitType, isLoading } = useQuery({
    queryKey: ['unitType', unit.typeId],
    queryFn: () => actions.unitTypes.get(unit.siteId, unit.typeId),
    staleTime: config.queries.site.staleTime,
    enabled: !_unitType,
  });

  useEffect(() => {
    if (unitType) {
      queryClient.setQueryData(
        ['unitType', { siteCode: site.code, typeCode: unitType.code }],
        unitType
      );
    }
  }, [unitType?.id]);

  if (isLoading) return <Loader />

  return (
    <UnitTypeCard unit={unit} unitType={_unitType || unitType} site={site} />
  )
}