import { useParams, useSearchParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useQuery, useQueryClient } from 'react-query';

import config from 'config';
import * as actions from 'actions';

import Loader from 'components/loader';
import NotFoundPage from 'components/not-found-page';
import SitePlan from 'components/site-plan';
import SiteDetailMain from 'components/site-detail';


const cms = wurd.block('siteDetail');

export default function SiteDetailPage() {
  const { site: siteCode } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  // Load site
  const { data: site, ...siteQuery } = useQuery({
    queryKey: ['site', siteCode],
    queryFn: () => actions.sites.get(siteCode),
    staleTime: config.queries.site.staleTime,
    initialData: () => {
      // TODO: Simplify this by storing sites in a simple array the store
      // Similar for unitTypes
      const queryData = queryClient.getQueryData(['sites']);
      if (!queryData) return;

      // sites query is an infinite query, so we need to flatten the pages
      const sites = queryData.pages.flat();
      const site = sites?.find(s => s.code === siteCode);

      return site;
    },
  });

  if (siteQuery.isLoading) return <Loader />;
  if (!site) return <NotFoundPage cms={cms.block('notFound')} />;
  if (searchParams.get('view') === 'map') return <SitePlan site={site} />;

  return (
    <SiteDetailMain
      cms={cms}
      site={site}
    />
  );
}
