import { useInfiniteQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import * as actions from 'actions';
import { getLangText } from 'utils/ui';
import Loader from 'components/loader';
import Paper from 'components/paper';
import Button from 'components/button';
import ListLoadMoreButton from 'components/list-load-more-button';
import UnitTypeCard from './unit-type-card';


const FadeEnd = styled.div({
  position: 'relative',
  '&::after': {
    position: 'absolute',
    pointerEvents: 'none',
    right: 0,
    top: 0,
    bottom: 0,
    left: '87.5%',
    content: '""',
    backgroundImage: `linear-gradient(
      to right,
      rgba(248, 248, 248, 0) 0%,
      rgba(248, 248, 248, 1) 100%
    )`,
  }
});

function GroupButton({
  isActive = false,
  children,
  ...rest
}) {
  return (
    <Button
      variant={ isActive ? 'primary' : 'secondary' }
      size="sm"
      style={{
        margin: '0 .5rem .5rem 0',
        flexShrink: 0,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default function SiteDetail_UnitTypeList({
  cms,
  site,
}) {
  const [searchParams] = useSearchParams();
  const limit = Number(searchParams.get('limit')) || 25;
  const groupCode = searchParams.get('group');

  // Load unit types
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['unitTypes', { siteCode: site.code, groupCode }],
    queryFn: function ({ pageParam: offset = 0 }) {
      const groupId = groupCode && site.unitTypeGroups.find(utg => utg.code === groupCode)?.id;

      return actions.unitTypes.fetch(site.code, {
        offset,
        limit,
        include: 'tags,priceFrom',
        groupId: groupId || '',
      });
    },
    getNextPageParam: function (lastPage, allPages) {
      return lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0);
    },
    enabled: !!site,
  });

  const unitTypes = infiniteQuery.data?.pages.flat(1);

  if (infiniteQuery.isLoading) return <Loader />;

  return (
    <>
      {site.unitTypeGroups.length > 0 && (
        <FadeEnd>
          <div className="d-flex" style={{ overflowX: 'auto' }}>
            <GroupButton
              as={Link}
              to={``}
              isActive={groupCode === null}
            >
              <cms.Text id="unitTypeGroups.all" />
            </GroupButton>

            {site.unitTypeGroups.map(unitTypeGroup => (
              <GroupButton
                key={unitTypeGroup.id}
                as={Link} to={`?group=${unitTypeGroup.code}`}
                isActive={groupCode === unitTypeGroup.code}
              >
                {getLangText(unitTypeGroup.title)}
              </GroupButton>
            ))}
          </div>
        </FadeEnd>
      )}

      {groupCode && <div className="text-muted">{getLangText(site.unitTypeGroups.find(g => g.code === groupCode)?.subtitle)}</div>}

      {unitTypes?.length > 0 ? (
        <>
          <ul className="list-unstyled d-flex flex-column gap-3 mt-2">
            {unitTypes.map(unitType => (
              <li key={unitType.id}>
                <UnitTypeCard unitType={unitType} site={site} />
              </li>
            ))}
          </ul>

          <ListLoadMoreButton
            infiniteQuery={infiniteQuery}
          />
        </>
      ) : (
        <Paper padded className="mt-5">
          <cms.Markdown id="empty.info" type="div" />
        </Paper>
      )}
    </>
  );
}
