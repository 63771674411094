import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import wurd from 'wurd-react';

import store from 'store';
import * as actions from 'actions';

import Button from 'components/button';
import DatePickerField from 'components/datepicker/field';
import Title from 'components/title';
import analytics from 'utils/analytics';
import { /* getUnitTypes, */ typePrice } from 'utils/site';
import { getLangText } from 'utils/ui';


const cms = wurd.block('unitDetail');

export default function UnitTypeDetail_DatePicker({
  site,
  unitType,
  unit,
}) {
  const { settings } = store.get();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(undefined);

  async function selectDate(e) {
    e.preventDefault();
    const { unitOrder: order } = store.get();
    if (order) {
      await actions.unitOrders.update(order.id, { startDate: date });
    }

    analytics.selectItem({
      item_list_name: 'Unit type',
      items: [{
        item_id: unitType.id,
        item_name: getLangText(unitType.title, ''),
        item_brand: getLangText(site.title, ''),
        price: typePrice(unitType),
        // index: getUnitTypes(site).findIndex(u => u.id === unitType.id),
      }]
    });

    setSearchParams({ date });
  }

  return (
    <>
      <Title cms={cms.block('date.title')} className="px-5" />

      <cms.Markdown id="date.info" type="div" className="my-4 px-5 text-muted lh-lg" />

      <form onSubmit={selectDate}>
        <DatePickerField
          name="date"
          value={date}
          show
          required
          dateFormat={settings.dateFormats.short}
          onChange={e => setDate(e.target.value)}
          min={site.minmaxBookingDates?.[0] || moment().format('YYYY-MM-DD')}
          max={site.minmaxBookingDates?.[1]}
          excludedDates={site.excludedBookingDates}
          cms={cms.block('date')}
        />

        {/* <div
          className="input-group mx-auto mt-3 summary collapsed"
          style={{ width: 320 }}
        >
          <span className="input-group-text"><i className="fa-fw far fa-layer-group" /></span>
          <div className="form-floating">
            <input
              id="unitName"
              className="form-control"
              placeholder="A01"
              // readOnly
            />
            <label htmlFor="unitName">Unit name</label>
          </div>
        </div>*/}

        <Button className="mt-3 d-block m-auto" type="submit">
          <cms.Text id="submitText" />
        </Button>
      </form>
    </>
  );
}
