import api, { apiUrl } from '../utils/api';
import errHandler from './_err-handler';


export function fetch(query) {
  return api.get(`/v1/sites${query ? `?${new URLSearchParams(query)}` : ''}`)
    .catch(errHandler);
}

// We set the includes here because using different query params negates the cached data in react-query
export function get(idOrCode, params = {
  include: ['minmaxBookingDates'],
}) {
  return api.get(`/v1/sites/${idOrCode}${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}

export function getPositions(idOrCode) {
  return api.get(`/v1/sites/${idOrCode}/positions`)
    .catch(errHandler);
}

export function sitemapUrl(idOrCode, query) {
  return `${apiUrl}/v1/sites/${idOrCode}/sitemap?${new URLSearchParams(query)}`
}