import api from '../utils/api';
import errHandler from './_err-handler';


export function fetch(siteIdOrCode, params) {
  return api.get(`/v1/sites/${siteIdOrCode}/unit-types${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}

// We set the includes here because using different query params negates the cached data in react-query
export function get(siteIdOrCode, unitTypeIdOrCode, params = {
  include: ['customFields', 'tags'],
}) {
  return api.get(`/v1/sites/${siteIdOrCode}/unit-types/${unitTypeIdOrCode}${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}
