import { useState } from 'react';
import styled from '@emotion/styled';
import Collapse from 'react-bootstrap/Collapse';
import moment from 'moment';
import DatePicker from './inline';


const Container = styled.div(`
  width: 320px;
  
  .form-floating {
    width: 281px;

    .form-control, label {
      padding-left: 1rem;
    }
  }
`);

export default function DatePickerField({ value, onChange, name, required, disabled, show, cms, dateFormat, ...props }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <cms.Object
        keys="label,placeholder"
        type={Container}
        className={`input-group m-auto summary ${!expanded ? 'collapsed' : ''}`}
        role="button"
        onClick={() => setExpanded(value ? (!expanded || show) : true)}
      >
        <span className="input-group-text"><i className="fa-fw far fa-calendar" /></span>
        <div className="form-floating">
          <input
            id="orderDate"
            className="form-control"
            name={name}
            defaultValue={(value && dateFormat) ? moment(value).format(dateFormat) : value}
            placeholder={cms.text('placeholder')}
            autoComplete="off"
            disabled={disabled}
            required={required}
            // readOnly
          />
          <label htmlFor="orderDate">{cms.text('label')}</label>
        </div>
      </cms.Object>
      {/* Not using Collapse transition because doesn't play well with react-dates, it stays hidden after toggling it */}
      {expanded && (
        <DatePicker onChange={e => { onChange(e); setExpanded(!!show); }} value={value} disabled={disabled} {...props} />
      )}
    </>
  );
}