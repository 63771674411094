import { Link } from 'react-router-dom';
import Title from 'components/title';
import Split from 'components/split-page';
import Progress from 'components/unit-progress';
import SiteCard from 'components/site-detail/site-card';
import UnitTypeList from 'components/site-detail/unit-type-list';


export default function SiteDetailMain({
  cms,
  site,
}) {
  return (
    <>
      <Progress site={site} />

      <Split className="mb-2">
        <div className="flex-1"><SiteCard site={site} showFeatures /></div>
        <div className="flex-1 px-2 px-sm-0 mt-lg-2" style={{ minWidth: 0 }}>
          <Title cms={cms.block('types')} />

          <UnitTypeList cms={cms} site={site} />
        </div>
      </Split>
    </>
  );
}
