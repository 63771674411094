export const apiUrl = process.env.REACT_APP_API_URL || `${window.location.origin}/api`;


export function _fetch(url, { body, headers, ...o } = {}) {
  const isJson = body && Object.getPrototypeOf(body) === Object.prototype; // most of the time we send plain 'json' objects
  return fetch(apiUrl + url, {
    headers: {
      ...isJson && { 'Content-Type': 'application/json' },
      Accept: 'application/json',
      // ...localStorage.getItem('accessToken') && { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') },
      ...headers
    },
    credentials: 'include',
    body: isJson ? JSON.stringify(body) : body,
    ...o
  });
}

export function fetchJson(url, opts) {
  return _fetch(url, opts).then(async r => {
    const data = await r.json().catch(() => null);
    if (r.ok) return data;

    const err = new Error(data?.error?.message || data);
    err.status = r.status;
    err.name = data?.error?.name;
    err.errors = data?.error.errors && Object.fromEntries(Object.entries(data.error.errors).map(([k, v]) => [k, v.message || v]));
    err.headers = Object.fromEntries(r.headers);
    throw err;
  });
}

export default {
  get: (url, opts) => fetchJson(url, { method: 'GET', ...opts }),
  post: (url, body, opts) => fetchJson(url, { method: 'POST', body, ...opts }),
  put: (url, body, opts) => fetchJson(url, { method: 'PUT', body, ...opts }),
  delete: (url, opts) => fetchJson(url, { method: 'DELETE', ...opts }),
};
