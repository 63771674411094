import { useSearchParams } from 'react-router-dom';
import isPojo from 'lodash/isPlainObject';

export default function useSearchData() {
  const [searchParams, setSearchParams] = useSearchParams();
  window.sd = searchParams;
  return [
    Object.fromEntries(searchParams),
    obj => setSearchParams(
      Object.entries(obj)
        .filter(([k, v]) => v !== undefined)
        .map(([k, v]) => [
          k,
          isPojo(v)
            ? Object.entries(v)
              .filter(([n, x]) => typeof x === 'number' ? x > 0 : x != null)
              .map(([n, x]) => `${n}:${x}`)
              .join(',')
            : v
        ])
    ),
  ];
}
