import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Dropdown from 'react-bootstrap/Dropdown';
import wurd from 'wurd-react';
import { getLangText, getPrice } from 'utils/ui';
import Markdown from 'components/markdown';
import Style from 'utils/style';
import sitemapObjects from './objects.json';

const cms = wurd.block('sitePlan');

const M_TO_FT = 3.28084;
const objectsByType = Object.fromEntries(sitemapObjects.map(o => [o.objectType, o]));

function getBounds(positions, _unitScale) {
  return positions.reduce(
    ([x, y, X, Y], p) => {
      const unitScale = p.type === 'object' ? 1 : _unitScale;
      const w = p.width * (p.scaleX || 1) * 20 * unitScale;
      const l = p.length * (p.scaleY || 1) * 20 * unitScale;
      const r = p.rotation * Math.PI / 180;
      return [
        Math.min(x, p.x),
        Math.min(y, p.y),
        Math.max(X, p.x + w * Math.cos(r) + l * Math.sin(r)),
        Math.max(Y, p.y + w * Math.sin(r) + l * Math.cos(r)),
      ];
    },
    [Infinity, Infinity, -Infinity, -Infinity]
  );
}

function viewBox([x, y, X, Y]) {
  return `${x - 100} ${y - 100} ${X - x + 200} ${Y - y + 200}`;
}

export default function ({ site, units, unitType, unitTypes, positions: _positions, showUnit }) {
  const unitScale = site.measure === 'm' ? M_TO_FT : 1;

  const positions = _positions
    .map(p => {
      if (p.type === 'object') return { ...objectsByType[p.objectType], ...p };
      const unit = units?.find(u => u.id === `${p.id}`);
      if (!unit) return { ...p, name: '–' }; // unit hidden by filter
      return { ...p, ...unit, state: 'available' };
    });
  const positionsByFloor = positions.reduce((o, p) => ({ ...o, [p.floor]: [...o[p.floor] || [], p] }), {});
  const floors = Object.keys(positionsByFloor).sort();
  const boundsByFloor = Object.fromEntries(floors.map(floor => [floor, getBounds(positionsByFloor[floor], unitScale)]));

  return (
    <>
      <Style>{`
.react-transform-component {
  transform-origin: top left;
}
.react-transform-wrapper,
.react-transform-component {
  display: flex;
  flex-direction: column;
  flex: 1;
}`}
      </Style>
      <div className="btn-toolbar d-flex align-items-center">
        {unitTypes?.length > 1 && (
          <Dropdown style={{ zIndex: 1, backgroundColor: '#fffc' }}>
            <Dropdown.Toggle size="sm" variant="link" id="d-type" className="text-decoration-none">
             Unit Type{unitType && <>: <strong>{getLangText(unitType.title)}</strong></>}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                active={!unitType}
                as={Link}
                to={`/sites/${site.code}?view=map`}
              >
                <cms.Text id="filters.type.all" />
              </Dropdown.Item>
              {unitTypes.map(t => (
                <Dropdown.Item
                  key={t.id}
                  active={t.id === unitType?.id}
                  as={Link}
                  to={`/sites/${site.code}/${t.code}?view=map`}
                >
                  {getLangText(t.title)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <TransformWrapper wheel={{ wheelDisabled: true }}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <TransformComponent>
              {floors.map(floor => (
                <Fragment key={floor}>
                  <Markdown inline className="text-primary fs-7 ms-3">{cms.text('plan.floor', { floor })}</Markdown>
                  <svg viewBox={viewBox(boundsByFloor[floor])} style={{ width: '99vw', flex: 1 }}>
                    {positionsByFloor[floor].map(p => {
                      const w = p.type === 'object' ? p.width : (p.width || 5) * unitScale;
                      const h = p.type === 'object' ? p.length : (p.length || 5) * unitScale;
                      const name = p.type === 'object' ? p.name : p.name.toUpperCase();

                      const width = w * (p.scaleX || 1) *  20;
                      const height = h * (p.scaleY || 1) * 20;

                      return (
                        <g
                          key={p.id}
                          transform={`translate(${p.x},${p.y}) rotate(${p.rotation || 0})`}
                          style={{ cursor: p.state === 'available' ? 'pointer' : null }}
                          onClick={() => p.state === 'available' && showUnit(p)}
                        >
                          {p.url
                            ? (
                              <>
                                {/*<image href={p.url} width={w * 20} height={h * 20} transform={`scale(${p.scaleX || 1},${p.scaleY || 1})`} preserveAspectRatio="none" />*/}
                                <image href={p.url} width={width} height={height} preserveAspectRatio="none" />
                                {p.objectType === 'toilet' && <rect width={width} height={height} stroke="black" strokeWidth={2} fill="none" />}
                              </>
                            ) : (
                              <>
                                <rect width={width} height={height} stroke="black" strokeWidth={2} fill={p.state === 'available' ? '#60C060' : '#ddd7'} />
                                <foreignObject width={width} height={height} fontSize={Math.min(width, height) / 4} fontFamily="sans-serif">
                                  <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                    <strong>{name}</strong>
                                    {p.defaultPrice !== undefined && <small>{getPrice(p.defaultPrice).replace(/[,.]00$/, '')}</small>}
                                  </div>
                                </foreignObject>
                              </>
                            )
                          }
                        </g>
                      );
                    })}
                  </svg>
                </Fragment>
              ))}
            </TransformComponent>
            <div className="btn-toolbar position-fixed flex-column align-items-end gap-2" style={{ bottom: 12, right: 4, filter: 'opacity(.85)' }} role="toolbar">
              <div className="btn-group-vertical">
                <button type="button" className="btn btn-primary btn-sm px-2" onClick={() => resetTransform()}><i className="far fa-location" /></button>
                <button type="button" className="btn btn-primary btn-sm px-2" onClick={() => zoomIn()}><i className="far fa-plus" /></button>
                <button type="button" className="btn btn-primary btn-sm px-2" onClick={() => zoomOut()}><i className="far fa-minus" /></button>
              </div>
            </div>
          </>
        )}
      </TransformWrapper>
    </>
  );
}
