import api, { _fetch } from 'utils/api';
import store from 'store';
import analytics from 'utils/analytics';
import errHandler from './_err-handler';


export async function create(data) {
  return api.post('/v1/units/orders', {
    submitLater: true,
    ...data,
  })
    .then(unitOrder => {
      store.set({ unitOrder });

      analytics.createdUnitOrder(unitOrder, { start: true });

      return unitOrder;
    })
    .catch(errHandler);
}

export function fetch(id) {
  return api.get(`/v1/units/orders/${id}`)
    .then(unitOrder => store.set({ unitOrder }))
    .catch(errHandler);
}

export function update(id, data) {
  return api.put(`/v1/units/orders/${id}`, data)
    .then(unitOrder => {
      if (data.submitNow) {
        analytics.createdUnitOrder(unitOrder);
      }

      store.set({ unitOrder });

      return unitOrder;
    })
    .catch(errHandler);
}

// Find an open (unsubmitted) order to resume
export async function fetchCurrent({ siteId, unitTypeId }) {
  try {
    const orders = await api.get(`/v1/units/orders`);

    const currentOrder = orders.find(o => o.siteId === siteId && o.unitTypeId === unitTypeId && o.orderState === 'notSubmitted') || null;

    store.set({ unitOrder: currentOrder });

    return currentOrder;
  } catch (err) {
    errHandler(err);
  }
}

// TODO why not passing orderId as first arg instead?
// export async function updateCurrent({ siteId, unitTypeId }, data) {
//   try {
//     const order = await fetchCurrent({ siteId, unitTypeId });
//     if (!order) throw new Error('Unit order not found');

//     return update(order.id, data);
//   } catch (err) {
//     errHandler(err);
//   }
// }

export function previewAgreement(orderId, data) {
  return _fetch(`/v1/units/orders/${orderId}/esign-preview`, {
    method: 'POST',
    body: {
      agreementType: 'html',
      ...data,
    },
  });
}
