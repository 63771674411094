import api from '../utils/api';
import errHandler from './_err-handler';


export function fetch(siteIdOrCode, params) {
  return api.get(`/v1/sites/${siteIdOrCode}/units${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}

export function get(siteIdOrCode, unitIdOrName, params = {
  include: ['customFields', 'features'],
}) {
  return api.get(`/v1/sites/${siteIdOrCode}/units/${unitIdOrName}${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}
